import dayjs from "dayjs"
/**
 * YYYY年MM月DD日 にフォーマットする
 **/
export const formatDate = (unixtime: number | null | undefined | string): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日") : ""
}
/**
 * YYYY年M月D日 にフォーマットする
 **/
export const formatSimpleDate = (unixtime: number | null | undefined | string): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年M月D日") : ""
}
/**
 * YYYY年MM月DD日 HH:mm:ss にフォーマットする
 **/
export const formatDateHour = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD HH:mm:ss にフォーマットする
 **/
export const formatDateHourIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD にフォーマットする
 **/
export const formatDateIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD") : ""
}
/**
 * YYYY.MM.DD にフォーマットする
 **/
export const formatDateDot = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY.MM.DD") : ""
}
/**
 * YYYY/MM/DD にフォーマットする
 **/
export const formatDateSlash = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY/MM/DD") : ""
}
/**
 * 現在のUnixTimeを取得する
 **/
export const getNowUnixTime = (): number => {
  return dayjs().valueOf()
}
/**
 * YYYY年MM月DD日 HH:mm にフォーマットする
 **/
export const formatDateWithoutSeconds = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm") : ""
}

/**
 * 使用期限を当日の23:59:59にする
 * @param expirationDays 期限までの日数。負の値を指定した場合は、現在日の前日の23:59:59の日付が設定されます。
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const getExpirationUnixTime = (expirationDays: number): number => {
  const expirationDate = dayjs().add(expirationDays, "day").endOf("day")
  return expirationDate.unix() * 1000
}

/**
 * 使用期限を指定した日付の23:59:59にする
 * @param expirationDate 期限日
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const mileageExpiredUnixtime = (): number => dayjs().add(1, "year").endOf("month").unix() * 1000
